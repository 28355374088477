import { useRoute } from "vue-router";

import Analytics from "@/services/Analytics";
import store from "@/store";

export function logAction(component = null, params = {}) {
  // NOTE: passing component name from component for now
  const user = store.getters.getUser();
  Analytics.logAction(component, user, params);
}

export function logPage(params = {}) {
  const route = useRoute();

  const user = store.getters.getUser();
  Analytics.logPage(route.name, user, params);
}