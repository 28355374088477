import Model from "./Model";
import Server from "../services/Server";

export default class DataExport extends Model {

  id = null;
  district_id = null;
  sftp_config_id = null;
  export_type = null;
  export_method = null;
  export_time = null;
  active = null;
  include_student_names = null;
  sftp_config = null;
  last_synced_at = null;
  created_at = null;
  updated_at = null;
  deleted_at = null;

  constructor(data = {}) {
    super(data);
    Object.assign(this, data);
  }

  async store() {
    return this._store(`data_exports/${this.id}`);
  }

  static async restore(id) {
    return Model._restore(DataExport, `data_exports/${id}`);
  }

  static async load(id, district_id) {
    return this._load(DataExport, `district/${district_id}/data_exports/${id}.json`);
  }

  static async create(params) {
    const path = `/districts/${params.district_id}/data_exports.json`;
    return await Server.post(path, params);
  }

  static async update(id, params) {
    const path = `/districts/${params.district_id}/data_exports/${id}.json`;
    return await Server.put(path, params);
  }

  static async archive(params) {
    const path = `/districts/${params.district_id}/data_exports/${params.id}.json`;
    return await Server.delete(path);
  }

  static async listExportFiles(params) {
    const path = `/districts/${params.district_id}/data_exports/${params.id}/data_export_files.json`;
    return await Server.get(path);
  }

  async archiveExportFile(params) {
    const path = `/districts/${this.district_id}/data_exports/${this.id}/data_export_files/${params.data_export_file_id}.json`;
    return await Server.delete(path);
  }

  async testDataExportConnection(id, district_id) {
    const path = `/districts/${district_id}/data_exports/${id}/test_sftp.json`;
    return await Server.patch(path);
  }

  static async activate(params) {
    const path = `/districts/${params.district_id}/data_exports/${params.id}/activate.json`;
    return await Server.patch(path);
  }

  static async deactivate(params) {
    const path = `/districts/${params.district_id}/data_exports/${params.id}/deactivate.json`;
    return await Server.patch(path);
  }

  // Run the export now without waiting for the scheduled export time
  static async export(params) {
    const path = `/districts/${params.district_id}/data_exports/${params.id}/export.json`;
    return await Server.patch(path);
  }
}
