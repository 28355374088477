<!-- eslint-disable vuejs-accessibility/mouse-events-have-key-events -->

<template>
  <div>
    <div
      v-if="this.isDistrictRosteringType(`hand`) && (this.user.isPrincipal() || this.user.isAssistant()) && !isLti">
      <div
        class="settings-btn"
        aria-haspopup="true"
        aria-controls="manage-menu"
        @click="toggleManage"
        @keydown.enter="toggleManage">
        <a
          id="trigger-button-manage"
          class="profile-menu-icon shadow-none"
          aria-label="Manage"
          button
          tabindex="0">
          <i class="fa fa-cog fa-xl"></i>
        </a>
      </div>
      <OverlayPanel
        id="manage-menu"
        ref="opm"
        appendTo="body"
        class="profile-menu">
        <div
          v-snip="1"
          class="h-[42px] px-24 pb-6 pt-16 text-16 font-semibold">
          {{ $t('menu.manage_school') }}
        </div>
        <div>
          <component
            :is="mobile ? 'PanelMenu' : 'TieredMenu'"
            :model="manageMenuItems.filter(m => showMenuItem(m))"
            class="w-full pb-10">
            <template #item="{item}">
              <a
                :ref="item.key"
                :aria-haspopup="item.items ? true : false"
                :aria-label="item.label"
                aria-expanded="false"
                :href="item.url"
                :target="item.newTab ? '_blank' : null"
                rel="noopener"
                role="menuitem"
                tabindex="0"
                class="p-menuitem-link"
                @click="item.url ? null : item.items ? openSubMenu($event) : item.action()"
                @keydown.enter="item.url ? null : item.items ? openSubMenu($event) : item.action()"
                @mouseover="item.url ? null : item.items ? openSubMenu($event, false) : null">
                <div
                  class="p-menuitem-label-wrapper"
                  @mouseover="!item.hover ? openSubMenu($event, false) : null">
                  <div class="!font-normal">
                    <p
                      v-if="item.subLabel"
                      v-snip="{ lines: 1, mode: 'js' }"
                      class="text-[11px] text-grey-2">
                      {{ item.subLabel }}
                    </p>
                    <p
                      v-snip="{ lines: 1, mode: 'js' }"
                      class="whitespace-pre text-14 text-black">
                      {{ item.label }}
                    </p>
                  </div>
                </div>
                <fa-icon
                  v-if="item.items"
                  icon="chevron-right"
                  size="sm"
                  color="grey-2"
                  class="pl-10"
                  @mouseover="item.items ? openSubMenu($event, false) : null">
                </fa-icon>
                <span
                  v-else-if="item.notification && showAnnouncementNotification && unreadAnnouncementCount > 0"
                  class="size-10 rounded-full bg-red" />
              </a>
            </template>
          </component>
        </div>
      </OverlayPanel>
    </div>
    <div
      class="mb-8 mr-auto flex justify-center"
      aria-haspopup="true"
      aria-controls="profile-menu"
      @click="toggle"
      @keydown.enter="toggle">
      <a
        :id="mobile ? 'trigger-button-mobile' : 'trigger-button'"
        class="profile-menu-icon"
        :class="{
          'size-[44px]': !user?.isStudent(),
          'size-[64px]': user?.isStudent()
        }"
        aria-label="Profile"
        button
        tabindex="0">
        <image-avatar
          class="mx-auto"
          :image="user?.image"
          :initials="user?.initials"
          altText="user avatar"
          :size="user?.isStudent() ? '60' : '40'"
          fontSize="15">
        </image-avatar>
        <span
          v-if="showAnnouncementNotification && unreadAnnouncementCount > 0"
          class="absolute bottom-0 right-0 block size-10 rounded-full bg-red ring-2 ring-white" />
      </a>
    </div>
    <OverlayPanel
      id="profile-menu"
      ref="op"
      appendTo="body"
      class="profile-menu">
      <div
        v-snip="1"
        class="h-[42px] px-24 pb-6 pt-16 text-16 font-semibold">
        {{ user?.name }}
      </div>
      <div>
        <component
          :is="mobile ? 'PanelMenu' : 'TieredMenu'"
          :model="menuItems.filter(m => showMenuItem(m))"
          class="w-full pb-10">
          <template #item="{item}">
            <a
              :ref="item.key"
              :aria-haspopup="item.items ? true : false"
              :aria-label="item.label"
              aria-expanded="false"
              :href="item.url"
              :target="item.newTab ? '_blank' : null"
              rel="noopener"
              role="menuitem"
              tabindex="0"
              class="p-menuitem-link"
              @click="item.url ? null : item.items ? openSubMenu($event) : item.action()"
              @keydown.enter="item.url ? null : item.items ? openSubMenu($event) : item.action()"
              @mouseover="item.url ? null : item.items ? openSubMenu($event, false) : null">
              <div
                class="p-menuitem-label-wrapper"
                @mouseover="!item.hover ? openSubMenu($event, false) : null">
                <div class="!font-normal">
                  <p
                    v-if="item.subLabel"
                    v-snip="{ lines: 1, mode: 'js' }"
                    class="text-[11px] text-grey-2">
                    {{ item.subLabel }}
                  </p>
                  <p
                    v-snip="{ lines: 1, mode: 'js' }"
                    class="whitespace-pre text-14 text-black">
                    {{ item.label }}
                  </p>
                </div>
              </div>
              <fa-icon
                v-if="item.items"
                icon="chevron-right"
                size="sm"
                color="grey-2"
                class="pl-10"
                @mouseover="item.items ? openSubMenu($event, false) : null">
              </fa-icon>
              <span
                v-else-if="item.notification && showAnnouncementNotification && unreadAnnouncementCount > 0"
                class="size-10 rounded-full bg-red" />
            </a>
          </template>
        </component>
      </div>
    </OverlayPanel>
  </div>
</template>

<script>
import {
  computed,
  nextTick,
  reactive,
  watch
} from "vue";
import {
  mapActions,
  mapGetters,
  useStore
} from "vuex";

import OverlayPanel from "primevue/overlaypanel";
import PanelMenu from "primevue/panelmenu";
import TieredMenu from "primevue/tieredmenu";

import { getUserRoles } from "@/composables/data/useUserSession";

import Helpers from "@/mixins/Helpers";
import ImageAvatar from "@/components/utils/ImageAvatar";
import FaIcon from "@/components/utils/FaIcon";

export default {
  components: {
    OverlayPanel,
    PanelMenu,
    TieredMenu,
    ImageAvatar,
    FaIcon
  },
  mixins: [
    Helpers
  ],
  props: {
    moreItems: {
      type: Array,
      required: false,
      default: () => []
    },
    mobile: {
      type: Boolean,
      required: false
    }
  },
  setup() {
    const store = useStore();

    const { data: roles } = getUserRoles();
    const currentRole = computed(() => roles?.value?.find(r => r.current_role));

    watch(roles, () => {
      const rolesTerms = currentRole.value?.terms?.map(t => t.term_id);
      const currentTerm = store.getters.currentTerm()?.id;
      if (rolesTerms?.includes(currentTerm) === false) {
        console.error("this role is not linked to the current term");
      }
    }, { immediate: true });

    return {
      roles,
      currentRole
    };
  },
  computed: {
    ...mapGetters([
      "activeRole",
      "terms"
    ]),
    menuItems() {
      const rolesConfig = structuredClone(this.roles);
      const showRoles = rolesConfig.length > 1;
      const showTerms = this.currentRole?.terms?.length > 0;

      const items = [
        // roles
        showRoles ? reactive({
          key: "roleSelector",
          label: this.currentRole?.title,
          subLabel: this.currentRole?.school?.name,
          items: rolesConfig.map(r => reactive({
            label: r.title,
            subLabel: r.school?.name,
            action: () => {
              this.changeRole({
                role: r.role,
                school: r.school?.school_id
              });
            },
            class: r.role === this.activeRole && (r.role === "Manager" || r.school?.school_id === this.user.current_school?.id) ? "active-item" : "",
            hover: true
          })),
          users: [
            "Manager", "Admin", "Manager", "Staff", "Principal", "Teacher", "Assistant", "Counselor"
          ]
        }) : null,
        // terms
        showTerms ? reactive({
          key: "termSelector",
          label: `${!this.currentTerm && this.terms?.length === 0 ? this.$t("menu.no_terms") : this.currentTerm?.name}`,
          subLabel: this.$t("menu.term"),
          items: this.currentRole?.terms.map(t => reactive({
            label: t.name,
            subLabel: t.academic_year !== t.name ? t.academic_year : null,
            action: () => this.setCurrentTerm(t.term_id),
            class: this.currentTerm?.id === t.term_id ? "active-item" : "",
            hover: true
          })),
          users: [
            "Manager", "Principal", "Teacher", "Assistant", "Counselor", "Student"
          ]
        }) : null,
        showRoles || showTerms ? { separator: true } : null,
        // profile
        reactive({
          key: "profile",
          label: this.$t("menu.profile"),
          action: () => {
            if (this.$store.getters.sidebars("profile")) {
              this.$store.dispatch("setSidebar", {
                key: "profile",
                value: false
              });
            }
            setTimeout(() => {
              this.$store.dispatch("setSidebar", {
                key: "profile",
                value: true
              });
            }, 100);
          }
        }),
        // notifications
        reactive({
          key: "announcements",
          label: this.$t("menu.announcements"),
          users: [
            "Manager", "Principal", "Assistant", "Counselor", "Teacher", "Student"
          ],
          action: () => {
            if (this.$store.getters.sidebars("announcements")) {
              this.$store.dispatch("setSidebar", {
                key: "announcements",
                value: false
              });
            }
            setTimeout(() => {
              this.$store.dispatch("setSidebar", {
                key: "announcements",
                value: true
              });
            }, 100);
          },
          notification: true
        }),
        // privacy
        reactive({
          key: "privacy",
          label: this.$t("menu.privacy"),
          url: "https://www.withwayfinder.com/privacy-policy",
          newTab: true
        }),
        // terms of service
        reactive({
          key: "terms_of_service",
          label: this.$t("menu.terms_of_service"),
          url: "https://www.withwayfinder.com/terms-of-service",
          newTab: true
        }),
        // help center
        reactive({
          key: "help_center",
          label: this.$t("menu.help_center"),
          url: "https://wayfinder.zendesk.com/hc/en-us",
          newTab: true,
          users: [
            "Manager", "Admin", "Staff", "Principal", "Teacher", "Assistant", "Counselor"
          ]
        }),
        // log out
        reactive({
          key: "logout",
          label: this.$t("menu.log_out"),
          url: "/logout"
        })
      ].filter(i => i !== null);

      let menuItems = [ ...this.moreItems, ...items ];
      if (this.isLti) {
        menuItems = menuItems.filter(i => i.key === "termSelector");
      }
      return menuItems;
    },
    manageMenuItems() {
      const items = [
        reactive({
          key: "admins",
          label: this.$t("menu.admins"),
          url: "/administrators"
        }),
        reactive({
          key: "teachers",
          label: this.$t("menu.teachers"),
          url: "/teachers"
        }),
        reactive({
          key: "classes",
          label: this.$t("menu.classes"),
          url: "/classes"
        })
      ];
      return items;
    },
    currentTerm() {
      return this.$store.getters.currentTerm();
    },
    unreadAnnouncementCount() {
      return this.$store.getters.getUnreadAnnouncementCount();
    },
    showAnnouncementNotification() {
      return this.$store.getters.announcementMenuNotification();
    }
  },
  methods: {
    ...mapActions([
      "changeRole",
      "setCurrentTerm"
    ]),
    openSubMenu($event, toggle = true) {
      const menuitem = $event.srcElement.closest(".p-menuitem");
      if (!menuitem) {
        return;
      }
      let alreadyOpen = false;
      // if already open
      if (menuitem.classList.contains("p-menuitem-active") && toggle) {
        menuitem.classList.remove("p-menuitem-active");
        alreadyOpen = true;
      }

      // close other open menu items
      const menuitems = document.querySelectorAll(".p-menuitem");
      menuitems.forEach(item => item.classList.remove("p-menuitem-active"));

      // if not already open then open
      if (!alreadyOpen) {
        menuitem.classList.add("p-menuitem-active");
      }
    },
    async toggle(event) {
      this.$refs.op.toggle(event);
      await nextTick();
    },
    async toggleManage(event) {
      this.$refs.opm.toggle(event);
      await nextTick();
    },
    showMenuItem(menu) {
      // TODO: rewrite this logic
      // NOTE: Managers always need to see waypoints - but if all prefs are false then the user ability for waypoints will be empty
      if (menu.key === "waypoints" && this.user.isManager()) {
        return true;
      }
      const hideWaypoints = menu?.key === "waypoints" && this.user.isStudent() && this.user.waypoints === false;
      return (menu?.model == null || this.hasAbility("read", menu.model)) && (menu?.users == null || menu.users.includes(this.activeRole)) && !hideWaypoints;
    }
  }
};
</script>

<style lang="scss">
.profile-menu:before,
.profile-menu:after {
  display: none;
}
.profile-menu {
  @apply rounded-[20px] shadow-card w-[250px] py-8;

  .p-overlaypanel-content {
    @apply p-0;
  }
  .p-tieredmenu {
    @apply rounded-b-[20px] rounded-t-0 border-none;
  }
  .p-menuitem-sublabel {
    @apply block;
  }
  .p-submenu-list {
    @apply max-h-[200px] overflow-y-auto rounded-[12px] overflow-hidden py-10;
  }
  .p-submenu-icon {
    @apply text-grey-2 text-[18px];
  }
  .p-menuitem-link {
    @apply py-8 px-24;
  }
  .p-menuitem-link:not(.p-disabled):hover {
    @apply bg-green/10;
  }
  .active-item .p-menuitem-label-wrapper::after {
    @apply text-green font-bold text-16 pl-10;
    font-family: "Font Awesome 6 Pro";
    content: "\f058";
  }
  .p-menuitem-label-wrapper {
    @apply flex items-center justify-between w-full overflow-x-hidden;
  }
  .p-menuitem-separator {
    @apply border-grey-4 border-[0.5px] mx-12;
  }
}

.profile-menu-icon {
  @apply
    relative
    flex items-center justify-center
    rounded-full
    border-[2px] border-transparent hover:border-green-70
    cursor-pointer shadow-md;
}

.settings-btn {
  @apply flex justify-center mr-auto mb-[18px];
}
</style>
