import utils from "./utils.js";

import Supports from "@/models/Supports";

export default {
  state: {
    supports: null
  },
  getters: {
    getSupports: (state, getters, rootState, rootGetters) => {
      let supports = state.supports;
      for (let i = 0; i < supports?.length; i++) {
        supports[i].label = rootState.users?.user.district.prefs_use_casel_skills && supports[i]?.casel_alignment ? supports[i].casel_alignment : supports[i].name;
      }
      return supports;
    },
    getSupport: (state, getters) => (support_id) => {
      return getters.getSupports?.find(support => support.id == support_id);
    },
    getSupportColor: (state, getters) => (support_id) => {
      return getters.getSupport(support_id)?.color;
    },
    getSupportLabel: (state, getters) => (support_id) => {
      return getters.getSupport(support_id)?.label;
    },
    getSupportName: (state, getters) => (support_id) => {
      return getters.getSupport(support_id)?.name;
    },
    getSupportType: (state, getters) => (support_id) => {
      return getters.getSupport(support_id)?.type;
    },
    getCoreSkillSupports: (state, getters) => {
      return getters.getSupports?.filter(support => support.type == "CoreSkillSupport");
    },
    getResourceSupports: (state, getters) => {
      return getters.getSupports.filter(support => support.type == "ResourceTypeSupport");
    },
    getSupportByName: (state, getters) => (name) => {
      return getters.getSupports().find(support => support.name == name);
    }
  },
  actions: {
    async loadSupports({ commit }, params) {
      try {
        let refresh = utils.try(params, "refresh", false);
        let search = utils.try(params, "search", null);
        let offset = utils.try(params, "offset", 0);
        let limit = utils.try(params, "limit", 100);
        let type = utils.try(params, "type", null);
        // temp fix until this gets switched over to tanstack
        let dontStore = params?.dontStore;
        delete params?.dontStore;
        let supports = refresh ? [] : await Supports.restore(type, search, 0, offset + limit);
        if (supports == null || supports.length == 0) {
          let deleted = utils.try(params, "deleted", false);
          supports = await Supports.load(type, search, offset, limit, deleted);
          if (supports && supports.length > 0) {
            await supports.store();
          }
        }
        if (!dontStore) {
          commit("setSupports", supports);
        }
        return Promise.resolve(supports);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async clearSupports({ commit }, params = {}) {
      await Supports.clear();
      commit("resetSupports");
      return Promise.resolve();
    }
  },
  mutations: {
    setSupports: (state, supports) => {
      state.supports = supports;
    },
    setSupport: (state, support) => {
      state.support = support;
    },
    resetSupports: (state) => {
      state.supports = null;
      state.support = null;
    }
  }
};
