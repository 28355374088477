import utils from "./utils.js";
import locales from "../locales";
import User from "@/models/User";
import Server from "@/services/Server";

export default {
  state: {
    user: null
  },
  getters: {
    getUser: (state) => () => {
      return state.user;
    },
    getUserId: (state, getters) => () => {
      return getters.getUser()?.id;
    },
    getUserAbility: (state, getters) => (action, subject) => {
      const user = getters.getUser();
      if (user?.abilities) {
        return user.abilities[subject]?.includes(action);
      }
      return false;
    },
    getUserCollectionRequests: (state, getters) => (collection_id) => {
      const user = getters.getUser();
      if (user?.collection_requests) {
        return user.collection_requests[collection_id];
      }
      return null;
    },
    activeRole: (state) => {
      return state.user?.view_as_role ? state.user?.view_as_role : state.user?.type;
    },
    activeSchool: (state) => {
      return state.user?.current_school?.id;
    }
  },
  actions: {
    async usersDetailsLookup({ commit }, params) {
      const url = process.env.VUE_APP_API_URL + "/users/users_lookup.json";
      const response = await Server.get(url, {user_ids: params.ids});
      return response;
    },
    async userRestore({
      commit, getters
    }) {
      const _user = getters.getUser();
      if (_user) {
        return Promise.resolve(_user);
      }
      let user = await User.restore();
      if (user) {
        locales.global.locale = user.locale;
        commit("setUser", user);
        return Promise.resolve(user);
      }
      else {
        commit("setUser", null);
        return Promise.resolve(null);
      }
    },
    async googleLogin({ commit }, params) {
      try {
        let user = await User.google(params.email, params.name, params.image, params.id_token, params.access_token, params.refresh_token, params.expires_at, params.classroom_code);
        await user.store();
        locales.global.locale = user.locale;
        commit("setUser", user);
        return Promise.resolve(user);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async azureLogin({ commit }, params) {
      try {
        let user = await User.azure(params.jwt, params.id_token, params.email, params.expires_at, params.name, params.classroom_code);
        await user.store();
        locales.global.locale = user.locale;
        commit("setUser", user);
        return Promise.resolve(user);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async classlinkLogin({ commit }, params) {
      try {
        let code = params.code;
        let classroom_code = params.classroom_code;
        let user = await User.classlink(code, classroom_code);
        await user.store();
        locales.global.locale = user.locale;
        commit("setUser", user);
        return Promise.resolve(user);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async cleverLogin({ commit }, params) {
      try {
        let user = await User.clever(params.code, params.redirect_uri, params.classroom_code);
        await user.store();
        locales.global.locale = user.locale;
        commit("setUser", user);
        return Promise.resolve(user);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async samlLogin({ commit }, params) {
      try {
        let user = await User.saml(params.user_access_token);
        await user.store();
        locales.global.locale = user.locale;
        commit("setUser", user);
        return Promise.resolve(user);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async userLogin({ commit }, params) {
      try {
        let user = await User.login(params.email, params.password, params.code, params.direct, params.key);
        await user.store();
        locales.global.locale = user.locale;
        commit("setUser", user);
        return Promise.resolve(user);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async userSignup({ commit }, params) {
      try {
        let user = await User.signup(params.type, params.name, params.email, params.password, params.image_data);
        await user.store();
        locales.global.locale = user.locale;
        commit("setUser", user);
        return Promise.resolve(user);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async userProfile({ commit }) {
      try {
        let user = await User.profile();
        await user.store();
        locales.global.locale = user.locale;
        commit("setUser", user);
        return Promise.resolve(user);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async ltiLogin({ commit }, params) {
      try {
        let user = await User.ltiLogin(params);
        await user.store();
        locales.global.locale = user.locale;
        commit("setUser", user);
        return Promise.resolve(user);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async userLocale({ commit }, params) {
      try {
        let user = await User.locale(params.locale);
        await user.store();
        locales.global.locale = params.locale;
        commit("setUser", new User(user));
        window.location.reload();
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async userAccessibilityTools({ commit }, boolean) {
      try {
        let user = await User.accessibilityTools(boolean);
        await user.store();
        commit("setUser", new User(user));
        window.location.reload();
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async userLogout({
      commit, dispatch
    }) {
      try {
        await User.logout();
        locales.locale = "en";
        await dispatch("resetStore");
        commit("resetUsers", null, {
          root: true
        });
        return Promise.resolve();
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async userUpdateNotificationSettings({ commit, getters }, boolean) {
      try {
        const _user = getters.getUser();
        let user = await User.notificationSettings(_user.id, boolean);
        await user.store();
        commit("setUser", new User(user));
        window.location.reload();
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async changeRole({ commit, dispatch }, params) {
      try {
        dispatch("setAppLoading");
        let user = await User.changeRole(params);
        commit("setSelectedAssessmentId", null);
        commit("setUser", new User(user));
        window.location.reload();
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async impersonate({ commit }, id) {
      let _user = new User();
      await _user.impersonate(id);
    },
    async stopImpersonating() {
      let _user = new User();
      await _user.stopImpersonating();
    }
  },
  mutations: {
    setUser: (state, user) => {
      state.user = user;
    },
    resetUsers: (state) => {
      state.user = null;
    }
  }
};
