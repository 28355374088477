import packageInfo from "../../package.json";
import mixpanel from "mixpanel-browser";
import ahoy from "ahoy.js";

import store from "@/store";

export default class Analytics {

  static logPage(name, user = null, params = {}) {
    if (!user || user.impersonating) {
      return false;
    }
    let properties = params || {};
    properties["screen_name"] = name;
    properties["app_version"] = packageInfo.version;
    if (user) {
      properties["user_type"] = store.getters.activeRole;
      properties["user_locale"] = user.locale;
    }
    if (user && user.district.name) {
      properties["district_name"] = user.district.name;
    }
    if (user && user.district_id) {
      properties["district_id"] = user.district_id;
    }
    if (user && user.district.salesforce_id) {
      properties["district_sf_id"] = user.district.salesforce_id;
    }
    if (user && user.current_school?.id) {
      properties["school_id"] = user.current_school.id;
      properties["school_name"] = user.current_school?.name;
    }
    try {
      if (process.env.VUE_APP_MIXPANEL_API_KEY && process.env.VUE_APP_MIXPANEL_API_KEY.length > 0 && process.env.VUE_APP_APP_ENV === "production" && !user.district.prefs_no_third_party) { //  && this.consistentHalfOfUsers(user.id) temporarily on hold 11/16/22
        mixpanel.set_config({
          ignore_dnt: true
        }); // tracking is for internal purposes only
        mixpanel.track(name, properties);
      }
    }
    catch {
      // ignore exception
    }
    try {
      if (user && (user.district?.salesforce_id || user.current_school?.salesforce_id) && user.isEducator() && !user.district.demo_type && process.env.VUE_APP_VITALLY_API_KEY && process.env.VUE_APP_VITALLY_API_KEY.length > 0 && !user.district.prefs_no_third_party && process.env.VUE_APP_APP_ENV === "production") {
        Vitally.track({
          event: "Page View: " + name,
          userId: user.email,
          properties: properties
        });
      }
    }
    catch {
      // ignore exception
    }
    try {
      if (user.token) {
        ahoy.configure({
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${user.token}`
          }
        });
        ahoy.track(name, properties);
      }
    }
    catch {
      // ignore exception
    }
  }

  /**
   * Brings back half of users, but always the same users, for Mixpanel flow tracking
   * @param {string}  user  The ID of the user
   * @returns {bool}  Whether the user is in the selection half
   */
  static consistentHalfOfUsers(userId) {
    let comparison = userId.charAt(0);
    if (isNaN(comparison)) {
      return comparison < "n";
    }
    else {
      return comparison < "5";
    }
  }

  static logAction(name, user = null, params = {}) {
    if (!user || user.impersonating) {
      return false;
    }
    let properties = params || {};
    properties["screen_name"] = name;
    properties["app_version"] = packageInfo.version;
    if (user) {
      properties["user_type"] = store.getters.activeRole;
      properties["user_locale"] = user.locale;
    }
    if (user && user.district.name) {
      properties["district_name"] = user.district.name;
    }
    if (user && user.district_id) {
      properties["district_id"] = user.district_id;
    }
    if (user && user.district.salesforce_id) {
      properties["district_sf_id"] = user.district.salesforce_id;
    }
    if (user && user.current_school?.id) {
      properties["school_id"] = user.current_school.id;
      properties["school_name"] = user.current_school?.name;
    }
    try {
      if (process.env.VUE_APP_MIXPANEL_API_KEY && process.env.VUE_APP_MIXPANEL_API_KEY.length > 0 && !user.district.prefs_no_third_party) {
        mixpanel.track(name, properties);
      }
    }
    catch {
      // ignore exception
    }
    try {
      if (user && user.token) {
        ahoy.configure({
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${user.token}`
          }
        });
        ahoy.track(name, properties);
      }
    }
    catch {
      // ignore exception
    }
  }

  static setUser(user) {
    let properties = {
      user_type: store.getters.activeRole,
      user_locale: user.locale,
      user_created: user.created_at,
      user_invited: user.invited_at,
      user_accepted: user.accepted_at
    };
    if (user.current_school?.id) {
      properties["school_id"] = user.current_school.id;
      properties["school_name"] = user.current_school?.name;
    }
    try {
      if (process.env.VUE_APP_MIXPANEL_API_KEY && process.env.VUE_APP_MIXPANEL_API_KEY.length > 0) {
        mixpanel.identify(user.id);
        mixpanel.people.set(properties);
        if (user.isStudent() == false) {
          mixpanel.people.set({
            "$name": user.name,
            "$email": user.email,
            user_name: user.name,
            user_email: user.email
          });
        }
      }
    }
    catch {
      // ignore exception
    }
  }

  static clearUser() {
    try {
      if (process.env.VUE_APP_MIXPANEL_API_KEY && process.env.VUE_APP_MIXPANEL_API_KEY.length > 0) {
        mixpanel.reset();
      }
    }
    catch {
      // ignore exception
    }
    try {
      ahoy.reset();
    }
    catch {
      // ignore exception
    }
  }

}
