import utils from "./utils.js";
import Term from "../models/Term";
import Terms from "../models/Terms";

export default {
  state: {
    terms: []
  },
  getters: {
    terms(state) {
      return state.terms;
    },
    getCurrentTerm: (state) => () => {
      if (state.terms && state.terms.length > 0) {
        let currentTerm = state.terms.filter(term => {
          return term.users_current_term === true;
        });
        return currentTerm[0];
      }
    },
    termAndYearExists: (state) => (name, academic_year, id) => {
      if (state.terms?.length > 0) {
        return state.terms.filter(term => term.id !== id && (term.name === name && term.academic_year === academic_year)).length > 0;
      }
      return null;
    }
  },
  actions: {
    async loadTerms({commit}, params) {
      try {
        let search = utils.try(params, "search", null);
        let offset = utils.try(params, "offset", 0);
        let limit = utils.try(params, "limit", 48);
        let terms = await Terms.load(search, offset, limit);
        if (terms && terms.length > 0) {
          await terms.store();
        }
        commit("setTerms", terms);
        return Promise.resolve(terms);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async saveTerm({commit}, params = {}) {
      try {
        let _term = new Term();
        _term.district_id = utils.try(params, "district_id", null);
        _term.id = utils.try(params, "id", null);
        _term.name = utils.try(params, "name", null);
        _term.start_date = utils.try(params, "start_date", null);
        _term.end_date = utils.try(params, "end_date", null);
        _term.academic_year = utils.try(params, "academic_year", null);
        _term.copied_from_term_id = utils.try(params, "copied_from_term_id", null);
        _term.copy_staff = utils.try(params, "copy_staff", false);
        _term.copy_teachers = utils.try(params, "copy_teachers", false);

        const response = await _term.save();
        commit("setTerm", response);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async deleteTerm({commit}, params = {}) {
      let term = new Term();
      term.id = params.id;
      await term.delete();
      commit("removeTerm", term);
      return Promise.resolve(null);
    }
  },
  mutations: {
    setTerms: (state, terms) => {
      state.terms = terms;
    },
    setTerm: (state, term) => {
      const index = state.terms.findIndex(_term => _term.id === term.id);
      if (index > -1) {
        state.terms.splice(index, 1, term);
      }
      else {
        state.terms.push(term);
      }
    },
    removeTerm(state, term) {
      if (state.terms) {
        const index =
          state.terms.findIndex(_term => _term.id === term.id);
        if (index > -1) {
          state.terms.splice(index, 1);
        }
      }
    },
    resetTerms: (state) => {
      state.terms = null;
      state.term = null;
    }
  }
};
