import Model from "./Model";

export default class Teacher extends Model {

  id = null;
  type = null;
  name = null;
  title = null;
  image = null;
  initials = null;

  created_at = null;
  updated_at = null;
  invited_at = null;
  accepted_at = null;
  sign_in_at = null;

  constructor(data = {}) {
    super(data);
    Object.assign(this, data);
    if (data && data["invited_at"]) {
      this.invited_at = data["invited_at"];
    }
    if (data && data["accepted_at"]) {
      this.accepted_at = data["accepted_at"];
    }
  }

  async store() {
    return this._store(`teachers/${this.id}`);
  }

  static async restore(id) {
    return Model._restore(Teacher, `teachers/${id}`);
  }

  static async load(id) {
    return Model._load(Teacher, `teachers/${id}.json`);
  }

  async save() {
    return this._save(Teacher, `teachers/${this.id}.json`, {
      name: this.name,
      email: this.email,
      classroom_ids: this.classroom_ids
    });
  }

  async invite(school_id, classroom_id = null, toolkit_ids = []) {
    let path = classroom_id && classroom_id.length > 0
      ? `schools/${school_id}/classrooms/${classroom_id}/teachers/invite.json`
      : `schools/${school_id}/teachers/invite.json`;
    return this._save(Teacher, path, {
      toolkit_ids: toolkit_ids,
      name: this.name,
      email: this.email
    });
  }

  async delete() {
    await this._delete(`teachers/${this.id}.json`);
    await this._remove(`teachers/${this.id}`);
    return null;
  }

}