<template>
  <div
    class="popper-wrapper block"
    :class="{
      'fixed-popper': fixedPopper
    }">
    <Popper
      :content="popperContent"
      :placement="placement"
      :closeDelay="closeDelay"
      :openDelay="openDelay"
      :hover="hover"
      :arrow="true">
      <slot></slot>
      <template
        v-if="popperTitle || isHtml"
        #content>
        <div
          v-if="popperTitle"
          class="mb-10 font-bold">
          {{ popperTitle }}
        </div>
        <div
          class="popper-content"
          v-html="popperContent">
        </div>
      </template>
    </Popper>
  </div>
</template>

<script>
import {
  defineComponent
} from "vue";
import Popper from "vue3-popper";

export default defineComponent({
  name: "Tooltip",
  components: {
    Popper
  },
  props: {
    popperTitle: {
      type: String,
      required: false
    },
    popperContent: {
      type: String,
      required: false
    },
    hover: {
      type: Boolean,
      required: false,
      default: false
    },
    placement: {
      type: String,
      required: false,
      default: "top"
    },
    closeDelay: {
      type: String,
      required: false,
      default: "0"
    },
    openDelay: {
      type: String,
      required: false,
      default: "0"
    },
    fixedPopper: {
      type: Boolean,
      required: false
    },
    isHtml: {
      type: Boolean,
      required: false,
      default: true
    }
  }
});
</script>

<style lang="scss" scoped>
:deep(.popper) {
  @apply text-14 max-w-[280px];
  color: #333 !important;
}
:deep(.popper-content > a) {
  @apply text-dark-green;
  text-decoration: underline;
}
.fixed-popper :deep(.popper) {
  position: fixed !important;
}
.popper-content {
  display: block;
  overflow: hidden;
  text-wrap: normal;
  text-wrap-mode: wrap;
}
</style>
