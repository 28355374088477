import utils from "./utils.js";

import DataExport from "@/models/DataExport";
import DataExports from "@/models/DataExports";

export default {
  state: {
    dataExport: null,
    dataExports: []
  },
  getters: {
    dataExport(state) {
      return state.dataExport;
    },
    dataExports(state) {
      return state.dataExports;
    }
  },
  actions: {
    async loadDataExports({
      commit, rootGetters
    }, params) {
      const district_id = rootGetters.district.id;
      const search = utils.try(params, "search", "");
      const offset = utils.try(params, "offset", 0);
      const limit = utils.try(params, "limit", 24);
      const deleted = utils.try(params, "deleted", false);
      const refresh = utils.try(params, "refresh", false);

      if (refresh) {
        let dataExports = await DataExports.load(district_id, search, offset, limit, deleted);
        if (dataExports && dataExports.length > 0) {
          await dataExports.store();
        }
      }

      let dataExports = await DataExports.restore(search, 0, offset + limit);

      commit("setDataExports", dataExports);
      return Promise.resolve(dataExports);
    },
    async createDataExport({
      commit, rootGetters
    }, params) {
      try {
        const export_type = utils.try(params, "export_type", null);
        const export_time = utils.try(params, "export_time", null);
        const include_student_names = utils.try(params, "include_student_names", null);
        const name = utils.try(params, "name", null);
        const host = utils.try(params, "host", null);
        const port = utils.try(params, "port", null);
        const path = utils.try(params, "path", "/");
        const username = utils.try(params, "username", null);
        const password = utils.try(params, "password", null);

        const data = {
          district_id: rootGetters.district.id,
          export_method: "sftp",
          export_type,
          export_time,
          active: 1,
          include_student_names,
          "sftp_config_attributes": {
            name,
            host,
            port,
            path,
            username,
            password
          }
        };

        let dataExport = params.id ? await DataExport.update(params.id, data) : await DataExport.create(data);
        return Promise.resolve(dataExport);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async getDataExportFiles({
      commit, rootGetters
    }, params) {
      try {
        let dataExport = null;
        const export_type = utils.try(params, "export_type", null);

        if (!params.id) {
          const data = {
            district_id: rootGetters.district.id,
            export_method: "download",
            export_type,
            export_time: new Date().toISOString(),
            active: 1
          };

          dataExport = await DataExport.create(data);
          commit("setDataExport", dataExport);

          await DataExport.export({
            id: dataExport.id,
            district_id: dataExport.district_id
          });
        }
        else {
          await DataExport.export({
            id: params?.id,
            district_id: params?.district_id
          });
        }

        // Wait 5 seconds for the export to complete
        await new Promise(resolve => setTimeout(resolve, 5000));
        const exportFiles = await DataExport.listExportFiles({
          id: dataExport?.id || params?.id,
          district_id: dataExport?.district_id || params?.district_id
        });

        return Promise.resolve({
          dataExport,
          exportFiles
        });
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async activateDataExport({ commit }, params) {
      const active = utils.try(params, "active", false);

      try {
        active ? DataExport.activate(params) : DataExport.deactivate(params);
        return Promise.resolve(null);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async archiveDataExport({ commit }, params) {
      try {
        await DataExport.archive(params);
        return Promise.resolve(null);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async testDataExportConnection(_args, params) {
      let _dataExport = new DataExport();
      const response = await _dataExport.testDataExportConnection(params.id, params.district_id);
      return Promise.resolve(response);
    }
  },
  mutations: {
    setDataExport: (state, dataExport) => {
      state.dataExport = dataExport;
    },
    setDataExports: (state, dataExports) => {
      state.dataExports = dataExports;
    },
    removeDataExport: (state, dataExport) => {
      state.dataExports = state.dataExports.filter(_dataExport => _dataExport.id !== dataExport.id);
    }
  }
};