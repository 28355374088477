import User from "@/models/User";
import Storage from "@/services/Storage";

const initialState = () => ({
  currentTerm: {},
  dialogs: {
    changeTerm: false,
    newThisFall: true,
    waypointsUpdate: true,
    ratingLesson: true,
    ratingActivity: true
  },
  sidebars: {
    student: true,
    profile: false,
    announcements: false
  },
  announcements: {
    lastSeenAt: null,
    menuNotification: true
  },
  uiDefaults: {
    timespanIndex: {},
    lessonTrays: {},
    wayfinderAcademyTray: {},
    libraryFilters: {
      default: {
        limit: null,
        offset: null,
        order_by: null,
        favorited: null,
        zero_prep: null,
        delivered: null,
        grade: null,
        groups: null,
        activity: null,
        delivery: null,
        duration: null,
        demo_content_type: null
      }
    },
    collectionFilters: {
      default: {
        userFilter: null,
        gradeBands: null,
        selectedTopic: null
      }
    },
    studentLibraryFilters: {
      default: {
        grade: null
      }
    },
    supportFilters: {
      default: {
        limit: null,
        offset: null,
        favorited: null,
        delivered: null,
        support_id: null,
        depth: null,
        practice: null
      }
    },
    userDirectory: {
      default: {
        filterUsers: true
      }
    },
    fastTrack: {},
    showRecommended: {},
    showAboutCollection: {},
    accessibilityOptions: {},
    resourceOptions: {}
  }
});

export default {
  state: initialState,
  actions: {
    async setCurrentTerm({
      commit, state
    }, term) {
      // if no term is passed in (ie on app load) then we need to refetch
      // the user object from api to see if current_term was updated
      // else we're updating the user object with hatever id is passed in
      const _user = new User();
      let user = term ? await _user.setUsersTerm(term) : await _user.fetchUserTerm();
      if (!user) {
        return;
      }
      commit("setSelectedAssessmentId", null);
      commit("setUser", new User(user));

      const userId = user.id;
      const termId = term ? term : user.current_term.id;
      const showDialog = [
        // check if this is on app load when we don't pass in a term
        !term,
        // stored term is not null and stored term does not match user's term
        state.currentTerm[userId] && state.currentTerm[userId] !== termId,
        // hide on production and release for now
        [
          "release", "production"
        ].includes(process.env.VUE_APP_APP_ENV) === false
      ];
      if (showDialog.every(d => d === true)) {
        commit("setDialog", {
          key: "changeTerm",
          value: true
        });
      }
      commit("setCurrentTerm", {
        userId,
        termId
      });

      return Promise.resolve(user);
    },
    setDialog({ commit }, {
      key,
      value
    }) {
      commit("setDialog", {
        key,
        value
      });
    },
    setSidebar({ commit }, {
      key,
      value
    }) {
      commit("setSidebar", {
        key,
        value
      });
    },
    toggleAboutCollection({ commit, rootGetters }, value) {
      const userId = rootGetters.getUserId();
      commit("setUiDefaults", {
        key: "showAboutCollection",
        userId: userId,
        value: value
      });
    },
    toggleWayfinderAcademyTray({ commit, rootGetters }, value) {
      const userId = rootGetters.getUserId();
      commit("setUiDefaults", {
        key: "wayfinderAcademyTray",
        userId: userId,
        value: value
      });
    },
    setAnnouncementLastSeenAt({ commit }, value) {
      commit("setAnnouncementLastSeenAt", value);
    },
    setAnnouncementMenuNotification({ commit }, value) {
      commit("setAnnouncementMenuNotification", value);
    },
    setLessonTrays({ commit, state, rootGetters }, {
      key,
      value,
      toolkit
    }) {
      const userId = rootGetters.getUserId();
      let openTrays = state.uiDefaults.lessonTrays[userId] ? state.uiDefaults.lessonTrays[userId][toolkit] || [] : [];
      value === true ? openTrays.push(key) : openTrays = openTrays.filter(t => t !== key);
      commit("setUiDefaults", {
        key: "lessonTrays",
        userId: userId,
        value: openTrays,
        subkey: toolkit
      });
    },
    setFastTrack({ commit, rootGetters }, {
      toolkit,
      value
    }) {
      const userId = rootGetters.getUserId();
      commit("setUiDefaults", {
        key: "fastTrack",
        userId: userId,
        value: value,
        subkey: toolkit
      });
    },
    toggleRecommended({ commit, rootGetters }, value) {
      const userId = rootGetters.getUserId();
      commit("setUiDefaults", {
        key: "showRecommended",
        userId: userId,
        value: value
      });
    },
    async resetStore({ commit }, clearAll = true) {
      clearAll ? await Storage.clear() : await Storage.clearData();
      commit("resetAnnouncements", null, {
        root: true
      });
      commit("resetAnswers", null, {
        root: true
      });
      commit("resetClassrooms", null, {
        root: true
      });
      commit("resetQuestions", null, {
        root: true
      });
      commit("resetResponses", null, {
        root: true
      });
      commit("resetSchools", null, {
        root: true
      });
      commit("resetStudents", null, {
        root: true
      });
      commit("resetSupports", null, {
        root: true
      });
      commit("resetTeachers", null, {
        root: true
      });
      commit("resetDistricts", null, {
        root: true
      });
    },
    setCollectionFilters({ commit, rootGetters }, filters) {
      commit("setCollectionFilters", {
        filters,
        userId: rootGetters.getUserId()
      });
    },
    resetLibraryFilters({ commit, rootGetters }) {
      commit("resetLibraryFilters", rootGetters.getUserId());
    },
    setLibraryFilters({ commit, rootGetters }, filters) {
      commit("setLibraryFilters", {
        filters,
        userId: rootGetters.getUserId()
      });
    },
    resetStudentLibraryFilters({ commit, rootGetters }) {
      commit("resetStudentLibraryFilters", rootGetters.getUserId());
    },
    setStudentLibraryFilters({ commit, rootGetters }, filters) {
      commit("setStudentLibraryFilters", {
        filters,
        userId: rootGetters.getUserId()
      });
    },
    resetSupportFilters({ commit, rootGetters }) {
      commit("resetSupportFilters", rootGetters.getUserId());
    },
    setSupportFilters({ commit, rootGetters }, filters) {
      commit("setSupportFilters", {
        filters,
        userId: rootGetters.getUserId()
      });
    },
    setAccessibilityOptions({ commit, rootGetters }, options) {
      commit("setAccessibilityOptions", {
        options,
        userId: rootGetters.getUserId()
      });
    },
    setResourceOptions({ commit, rootGetters }, options) {
      commit("setResourceOptions", {
        options,
        userId: rootGetters.getUserId()
      });
    },
    resetUserDirectory({ commit, rootGetters }) {
      commit("resetUserDirectory", rootGetters.getUserId());
    },
    setUserDirectory({ commit, rootGetters }, directory) {
      commit("setUserDirectory", {
        directory,
        userId: rootGetters.getUserId()
      });
    }
  },
  mutations: {
    setCollectionFilters(state, {
      filters, userId
    }) {
      state.uiDefaults.collectionFilters[userId] = { ...filters };
    },
    setLibraryFilters(state, {
      filters, userId
    }) {
      state.uiDefaults.libraryFilters[userId] = {
        ...filters
      };
    },
    resetLibraryFilters(state, userId) {
      state.uiDefaults.libraryFilters[userId] = {
        ...state.uiDefaults.libraryFilters.default
      };
    },
    setStudentLibraryFilters(state, {
      filters, userId
    }) {
      state.uiDefaults.studentLibraryFilters[userId] = {
        ...filters
      };
    },
    resetStudentLibraryFilters(state, userId) {
      state.uiDefaults.studentLibraryFilters[userId] = {
        ...state.uiDefaults.studentLibraryFilters.default
      };
    },
    setSupportFilters(state, {
      filters, userId
    }) {
      state.uiDefaults.supportFilters[userId] = {
        ...filters
      };
    },
    resetSupportFilters(state, userId) {
      state.uiDefaults.supportFilters[userId] = {
        ...state.uiDefaults.supportFilters.default
      };
    },
    setUserDirectory(state, {
      directory, userId
    }) {
      state.uiDefaults.userDirectory[userId] = {
        ...directory
      };
    },
    setAccessibilityOptions(state, {
      options, userId
    }) {
      state.uiDefaults.accessibilityOptions[userId] = options;
    },
    setResourceOptions(state, {
      options, userId
    }) {
      state.uiDefaults.resourceOptions[userId] = options;
    },
    resetUserDirectory(state, userId) {
      state.uiDefaults.userDirectory[userId] = {
        ...state.uiDefaults.userDirectory.default
      };
    },
    setCurrentTerm(state, {
      userId, termId
    }) {
      state.currentTerm[userId] = termId;
    },
    setDialog(state, {
      key, value
    }) {
      state.dialogs[key] = value;
    },
    setSidebar(state, {
      key, value
    }) {
      state.sidebars[key] = value;
    },
    setAnnouncementLastSeenAt(state, value) {
      state.announcements["lastSeenAt"] = value;
    },
    setAnnouncementMenuNotification(state, value) {
      state.announcements["menuNotification"] = value;
    },
    setUiDefaults(state, {
      key, userId, value, subkey
    }) {
      !(userId in state.uiDefaults[key]) && (state.uiDefaults[key][userId] = {});
      if (subkey) {
        !(subkey in state.uiDefaults[key][userId]) && (state.uiDefaults[key][userId][subkey] = {});
        state.uiDefaults[key][userId][subkey] = value;
      }
      else {
        state.uiDefaults[key][userId] = value;
      }
    },
    resetUx(state) {
      const initial = initialState();
      Object.keys(initial).forEach(key => {
        state[key] = initial[key];
      });
    }
  },
  getters: {
    currentTerm: (state, rootGetters) => () => {
      const terms = rootGetters.terms;
      const userId = rootGetters.getUserId();
      if (terms?.length > 0) {
        return terms.find(t => t.id === state.currentTerm[userId]);
      }
      return null;
    },
    dialogs: (state) => (key) => {
      return state.dialogs[key];
    },
    sidebars: (state) => (key) => {
      return state.sidebars[key];
    },
    announcementLastSeenAt: (state) => () => {
      return state.announcements["lastSeenAt"];
    },
    announcementMenuNotification: (state) => () => {
      return state.announcements["menuNotification"];
    },
    // ui defaults
    getCollectionFilters: (state, rootGetters) => () => {
      return state.uiDefaults.collectionFilters[rootGetters.getUserId()];
    },
    getLibraryFilters: (state, rootGetters) => () => {
      return state.uiDefaults.libraryFilters[rootGetters.getUserId()];
    },
    getStudentLibraryFilters: (state, rootGetters) => () => {
      return state.uiDefaults.studentLibraryFilters[rootGetters.getUserId()];
    },
    getSupportFilters: (state, rootGetters) => () => {
      return state.uiDefaults.supportFilters[rootGetters.getUserId()];
    },
    getUserDirectory: (state, rootGetters) => () => {
      return state.uiDefaults.userDirectory[rootGetters.getUserId()];
    },
    timespanIndex: (state, rootGetters) => {
      const userId = rootGetters.getUserId();
      const index = state.uiDefaults.timespanIndex[userId];
      return typeof index !== "undefined" ? index : 2;
    },
    lessonTrays: (state, rootGetters) => (toolkit) => {
      const userId = rootGetters.getUserId();
      if (userId && toolkit) {
        return state.uiDefaults.lessonTrays[userId] ? state.uiDefaults.lessonTrays[userId][toolkit] : null;
      }
      return null;
    },
    fastTrack: (state, rootGetters) => (toolkit) => {
      const userId = rootGetters.getUserId();
      if (userId && toolkit) {
        return state.uiDefaults.fastTrack[userId] ? state.uiDefaults.fastTrack[userId][toolkit] : null;
      }
      return null;
    },
    showRecommended(state, rootGetters) {
      const userId = rootGetters.getUserId();
      if (userId) {
        const value = state.uiDefaults.showRecommended[userId];
        return typeof value !== "undefined" ? value : true;
      }
      return null;
    },
    showAboutCollection(state, rootGetters) {
      const userId = rootGetters.getUserId();
      if (userId) {
        return state.uiDefaults.showAboutCollection[userId];
      }
      return null;
    },
    wayfinderAcademyTray(state, rootGetters) {
      const value = state.uiDefaults.wayfinderAcademyTray[rootGetters.getUserId()];
      return typeof value !== "undefined" ? value : true;
    },
    accessibilityOptions(state, rootGetters) {
      const value = state.uiDefaults.accessibilityOptions[rootGetters.getUserId()];
      return typeof value !== "undefined" ? value : false;
    },
    resourceOptions(state, rootGetters) {
      const value = state.uiDefaults.resourceOptions[rootGetters.getUserId()];
      return typeof value !== "undefined" ? value : false;
    }
  }
};
